import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

window.addEventListener('load', function () {
  const Swiper = window.Swiper;
  const CarouselContainer = document.querySelectorAll(
    '.slider-media-text__container'
  );
  if (0 < CarouselContainer.length) {
    new Swiper('.slider-media-text__container', {
      direction: 'horizontal',
      paginationClickable: true,
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      keyboard: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: function (index, className) {
          if (index < 9) {
            return (
              '<span class="' + className + '">0' + (index + 1) + '</span>'
            );
          } else {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          }
        },
      },
    });
  }
});
